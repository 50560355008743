<template>
  <base-layout :loading="loading">
    <template v-slot:title>
      <div class="text-lg">
        パスワードを変更する
      </div>
    </template>
    <div
      class="flex items-center h-full justify-center"
    >
      <form
        @submit.prevent="onSubmit"
        class="px-10 pt-10"
      >
        <text-field
          type="password"
          label="パスワード"
          class="mb-5"
          v-model="formData.password"
        ></text-field>
         <text-field
          type="password"
          label="パスワード確認"
          class="mb-5"
          v-model="formData.password_confirm"
        ></text-field>
          <button
            type="submit"
            class="w-full success "
          >
            送信
          </button>
      </form>
    </div>
  </base-layout>
</template>
<script >

import {defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';

import useAuth from '@/types/Auth';
import useFlashMessage from '@/types/FlashMessage';

export default defineComponent({
  components: {
  },

  setup() {
    const router = useRouter();

    const {
      setMessage  
    } = useFlashMessage();

    const {
      updatePassword
    } = useAuth();

    
    const formData = ref({
      password: '',
      password_confirm: ''
    });
    
    const loading = ref(false);

    const onSubmit = async () => {
      // validate
      try {
        loading.value = true;
        const currentRoute = router.currentRoute.value.params;
        await updatePassword(formData.value, currentRoute.uidb64, currentRoute.token);
        router.push({ name: 'ResetPasswordComplete' }) ;
      } catch (err) {
        console.log(err)
        if (err.response) {
          const { data } = err.response;
          if (data && data.Error) {
            setMessage({ type: 'error', message: data.Error })
          } else {
            const errors = Object.values(data);
            if (errors.length > 0 ){
              if (errors[0].length > 0 ) {
                setMessage({ type: 'error', message: errors[0][0] })
              }
            }
          }
        } else {
          console.error('network error')
        }
      }
      loading.value = false;
      
    };
    return {
      loading,
      formData,
      onSubmit,
    };
  }
})
</script>


